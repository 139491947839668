var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.cls },
    [_vm._t("default"), _vm.loadingVisible ? _c("zui-loading") : _vm._e()],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }